import { DataGrid, GridToolbar } from '@mui/x-data-grid';
import React, { useCallback, useEffect, useMemo, useState } from 'react';
import { z } from 'zod';

import {
  Autocomplete,
  Button,
  Divider,
  FormControl,
  Grid,
  InputLabel,
  MenuItem,
  Select,
  SelectChangeEvent,
  TextField,
  Typography,
} from '@mui/material';
import { useTokenInterceptor } from 'app/hooks';
import { MACHINE_TYPE } from 'app/MACHINE_TYPE';
import { clsx } from 'clsx';
import { environment } from 'environment/environment';
import { useHistory, useLocation } from 'react-router-dom';
import { DefaultProps } from 'utils/react';
import * as API from './machine-search.client';

type OnChange = (
  f: <T extends Record<string, string | undefined>>(_: T) => T,
) => void;

const GridSelect = ({
  onChange,
  values,
  field,
  options,
  required = false,
}: {
  onChange: OnChange;
  values: Record<string, unknown>;
  field: string;
  options: string[];
  required?: boolean;
}) => {
  const onChangeField = useCallback(
    (event: SelectChangeEvent<any>) => {
      const value = !event.target.value ? undefined : event.target.value;
      onChange((m) => ({ ...m, [field]: value }));
    },
    [onChange, field],
  );

  return (
    <Grid item>
      <FormControl fullWidth>
        <InputLabel variant="outlined" htmlFor="brand-select">
          {field}
        </InputLabel>
        <Select
          required={required}
          MenuProps={{
            MenuListProps: {
              sx: {
                overflowY: 'scroll',
                maxHeight: '15rem',
              },
            },
          }}
          sx={{ minWidth: '225px' }}
          placeholder="placeholder"
          onChange={onChangeField}
          label={field}
          value={values[field] || ''}
        >
          {[...options, undefined].map((value) => (
            <MenuItem
              dense
              sx={{ minHeight: '2rem' }}
              key={value || 'undefined'}
              value={value}
              selected={values[field] === value}
            >
              {value || ' '}
            </MenuItem>
          ))}
        </Select>
      </FormControl>
    </Grid>
  );
};

const GridTextInput = ({
  onChange,
  values,
  field,
  disabled = false,
  required = false,
}: {
  onChange: OnChange;
  values: Record<string, unknown>;
  field: string;
  disabled?: boolean;
  required?: boolean;
}) => {
  const onChangeField = useCallback(
    (event: React.ChangeEvent<HTMLTextAreaElement | HTMLInputElement>) => {
      const value = !event.target.value ? undefined : event.target.value;
      onChange((m) => ({ ...m, [field]: value }));
    },
    [onChange, field],
  );

  return (
    <Grid item>
      <FormControl fullWidth>
        <TextField
          required={required}
          label={field}
          disabled={disabled}
          onChange={onChangeField}
          value={values[field] || ''}
        />
      </FormControl>
    </Grid>
  );
};

const GridNumberInput = ({
  onChange,
  values,
  field,
}: {
  onChange: OnChange;
  values: Record<string, unknown>;
  field: string;
}) => {
  const onChangeField = useCallback(
    (event: React.ChangeEvent<HTMLTextAreaElement | HTMLInputElement>) => {
      const value = !event.target.value ? undefined : event.target.value;
      onChange((m) => ({ ...m, [field]: value }));
    },
    [onChange, field],
  );

  return (
    <Grid item>
      <FormControl fullWidth>
        <TextField
          label={field}
          onChange={onChangeField}
          value={values[field] || ''}
        />
      </FormControl>
    </Grid>
  );
};

const GridAutocomplete = ({
  onChange,
  values,
  field,
  options,
  required = false,
}: {
  onChange: OnChange;
  values: Record<string, unknown>;
  field: string;
  options: string[];
  required?: boolean;
}) => {
  const onChangeField = useCallback(
    (_: React.SyntheticEvent, value: string | null) => {
      onChange((m) => ({ ...m, [field]: value || undefined }));
    },
    [onChange, field],
  );

  return (
    <Grid item>
      <FormControl fullWidth>
        <Autocomplete
          disablePortal
          options={options}
          sx={{ minWidth: '225px' }}
          value={(values[field] as string) || null}
          onChange={onChangeField}
          renderInput={(params) => (
            <TextField {...params} required={required} label={field} />
          )}
        />
      </FormControl>
    </Grid>
  );
};

export enum TRANSMISSION_TYPE {
  AUTOMATIC = 'AUTOMATIC',
  FULL_POWER_SHIFT = 'FULL_POWER_SHIFT',
  HYDROSTATIC = 'HYDROSTATIC',
  LO = 'LO',
  MANUAL = 'MANUAL',
  OTHER = 'OTHER',
  SEMI_POWER_SHIFT = 'SEMI_POWER_SHIFT',
  STEPLESS = 'STEPLESS',
  SYNCROMESH = 'SYNCROMESH',
}

export const ParamsForm = ({
  onChange,
  values,
  brands,
}: {
  onChange: OnChange;
  values: Record<string, unknown>;
  brands: string[];
}) => {
  const props = { onChange, values };

  const machineTypeOptions = useMemo(
    () =>
      Object.entries(MACHINE_TYPE).map(([key, value]) => ({
        label: key,
        value: value,
      })),
    [],
  );

  return (
    <>
      <Grid container spacing={2}>
        <GridAutocomplete required field="BRAND" options={brands} {...props} />
        <GridTextInput required field="RAW_MODEL" {...props} />
        <Grid item>
          <FormControl fullWidth>
            <Autocomplete
              disablePortal
              options={machineTypeOptions}
              sx={{ minWidth: '225px' }}
              value={
                machineTypeOptions.find(
                  (option) => option.value === values.MACHINE_TYPE,
                ) || null
              }
              onChange={(_, option) => {
                onChange((m) => ({
                  ...m,
                  MACHINE_TYPE: option?.value || undefined,
                }));
              }}
              getOptionLabel={(option) => option.label}
              renderInput={(params) => (
                <TextField {...params} label="MACHINE_TYPE" />
              )}
            />
          </FormControl>
        </Grid>
        <GridSelect
          field="TRANSMISSION_TYPE"
          options={Object.values(TRANSMISSION_TYPE)}
          {...props}
        />
        <GridNumberInput field="YEAR_OF_PRODUCTION" {...props} />
        <GridNumberInput field="ENGINE_HP" {...props} />
      </Grid>
    </>
  );
};

function rotate<T>(xs: T[]) {
  return [xs[xs.length - 1]].concat(xs.slice(0, xs.length - 1));
}

function rowFromDescription(
  description: API.Description,
  i: number,
  xs: API.Description[],
) {
  const time = (description.timestamp - xs[0].timestamp).toFixed(2);
  let className = '';
  const message = description.kind;
  switch (description.kind) {
    case 'INPUT':
      className = clsx('bg-slate-100');
      break;
    case 'CANDIDATE_EARLY_SKIP':
      className = clsx('bg-amber-100');
      break;
    case 'CANDIDATE_FULL_MATCH':
    case 'CANDIDATE_PARTIAL_MATCH':
      className = 'bg-sky-100';
      break;
    case 'RESULT_PERFECT_MATCH':
    case 'RESULT_BEST_FULL_MATCH':
    case 'RESULT_BEST_PARTIAL_MATCH':
      className = 'bg-emerald-100 border-2 border-emerald-500';
      break;
    case 'RESULT_NO_MATCHES_FOUND':
    case 'RESULT_AMBIGUOUS_PARTIAL_MATCH':
      className = 'bg-rose-100 border-2 border-rose-500';
      break;
  }

  return {
    id: i,
    time,
    message,
    className,
    ...description.values,
  };
}

const columns = [
  { field: 'time', headerName: 'Time (ms)' },
  {
    hideable: false,
    field: 'message',
    headerName: 'Message',
    minWidth: 240,
  },
  { field: 'length', headerName: 'total', width: 40 },
  { field: 'position', headerName: '#', width: 30 },
  { field: 'fullMatchScore', minWidth: 180 },
  { field: 'partialMatchScore', minWidth: 180 },
  { field: 'BRAND', minWidth: 300 },
  { field: 'RAW_MODEL', minWidth: 300 },
  {
    field: 'normalizedRawModel',
    headerName: 'RAW_MODEL (normalized)',
    minWidth: 300,
  },

  { field: 'MODEL', minWidth: 300 },
  {
    field: 'normalizedModel',
    headerName: 'MODEL (normalized/aliased)',
    minWidth: 300,
  },
  { field: 'modelEditScore', headerName: 'distance (MODEL)' },
  { field: 'modelScore', minWidth: 180 },
  { field: 'modelScoreNormalized', minWidth: 180 },

  { field: 'BASE_MODEL', minWidth: 300 },
  {
    field: 'normalizedBaseModel',
    headerName: 'BASE_MODEL (normalized/aliased)',
    minWidth: 300,
  },
  { field: 'baseModelEditScore', headerName: 'distance (BASE_MODEL)' },
  { field: 'baseModelScore', minWidth: 180 },
  { field: 'baseModelScoreNormalized', minWidth: 180 },

  { field: 'isAlias', headerName: 'alias?', width: 70 },
  { field: 'aliasScore', minWidth: 180 },
  { field: 'aliasScoreNormalized', minWidth: 180 },

  { field: 'TRANSMISSION_TYPE', minWidth: 180 },
  { field: 'transmissionTypeScore', minWidth: 180 },
  { field: 'transmissionTypeScoreNormalized', minWidth: 180 },

  { field: 'MACHINE_TYPE', minWidth: 160 },
  { field: 'machineTypeScore', minWidth: 180 },
  { field: 'machineTypeScoreNormalized', minWidth: 180 },

  { field: 'YEAR_OF_PRODUCTION', minWidth: 180 },
  { field: 'YEAR_OF_PRODUCTION_START', minWidth: 220 },
  { field: 'YEAR_OF_PRODUCTION_END', minWidth: 220 },
  { field: 'yearOfProductionScore', minWidth: 180 },
  { field: 'yearOfProductionScoreNormalized', minWidth: 180 },

  { field: 'ENGINE_HP' },
  { field: 'engineHpScore', minWidth: 180 },
  { field: 'engineHpScoreNormalized', minWidth: 180 },

  { field: 'machineCount' },
  { field: 'machineCountScore', minWidth: 180 },
  { field: 'machineCountScoreNormalized', minWidth: 180 },
];

const columnVisibilityModel = {
  SUB_TYPE: false,
};

const Table = ({ descriptions: xs }: { descriptions: API.Description[] }) => {
  const rows = useMemo(
    () =>
      // rotating to move the result step to the top
      rotate(xs.map(rowFromDescription)),
    [xs],
  );

  return (
    <div style={{ height: '60vh' }}>
      <DataGrid
        disableSelectionOnClick
        density="compact"
        initialState={{ columns: { columnVisibilityModel } }}
        getRowClassName={({ row: { className } }) => className}
        rows={rows}
        columns={columns}
        components={{
          Toolbar: GridToolbar,
        }}
      />
    </div>
  );
};

export const SearchParamsSchema = z
  .object({
    BRAND: z.string().min(1).optional(),
    RAW_MODEL: z.string().min(1).optional(),
    MACHINE_TYPE: z.string().min(1).optional().default('tractor'),
    TRANSMISSION_TYPE: z.string().min(1).optional(),
    YEAR_OF_PRODUCTION: z.string().min(1).optional(),
    ENGINE_HP: z.string().min(1).optional(),
  })
  .transform((_) => {
    for (const [key, value] of Object.entries(_)) {
      if (value === undefined) {
        delete _[key];
      }
    }
    return _;
  });

const useParams = () => {
  const { pathname, search } = useLocation();
  const { replace } = useHistory();

  const previous = useMemo(() => {
    const raw = {};
    new URLSearchParams(search).forEach((value, key) => {
      raw[key] = value;
    });
    return SearchParamsSchema.parse(raw);
  }, [search]);

  const setParams = useCallback<OnChange>(
    (f) => {
      const next = new URLSearchParams(SearchParamsSchema.parse(f(previous)));
      replace(`${pathname}?${next.toString()}`);
    },
    [replace, pathname, previous],
  );

  return { params: previous, setParams };
};

export const DebugParseModel: React.FC<DefaultProps> = () => {
  const [state, setState] = useState<API.Description[]>([]);
  const [brands, setBrands] = useState<string[]>([]);
  const { params, setParams } = useParams();
  if (environment.nodeEnv !== 'development') {
    // eslint-disable-next-line react-hooks/rules-of-hooks
    useTokenInterceptor(API.client, environment.machineSearch.auth0Audience);
  }

  useEffect(() => {
    API.getBrands().then(setBrands);
  }, []);

  const parseModel = useCallback(() => {
    API.parseModel(API.ParseModelParamsSchema.parse(params)).then(setState);
  }, [params]);

  return (
    <main className="pb-4 min-h-5">
      <ParamsForm brands={brands} values={params} onChange={setParams} />
      <div
        style={{
          padding: '1rem',
          width: '100%',
          display: 'flex',
          justifyContent: 'center',
        }}
      >
        <Button onClick={parseModel} size="large">
          Parse Model
        </Button>
      </div>
      <Divider sx={{ margin: '1rem 0' }} />
      {state.length > 0 ? (
        <Table descriptions={state} />
      ) : (
        <div
          style={{
            padding: '1rem',
            width: '100%',
            display: 'flex',
            justifyContent: 'center',
          }}
        >
          <Typography>
            Enter Your Data, And Press &quot;PARSE MODEL&quot;
          </Typography>
        </div>
      )}
    </main>
  );
};
